import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51N9N1JJIGhEasScuCeaebL5TWijiBjW5S4tw0TTGufO5LTnE40OYIn6011J8JRgTkk55hlm5R6O4DeEFcbLSc2i700Z2u863IX"
    );
  }
  return stripePromise;
};
export default initializeStripe;
