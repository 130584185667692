import { ReactComponent as CopyIcon } from "../assets/copy.svg";
import { useDropzone } from 'react-dropzone';
import { ReactComponent as ArrowRightIcon } from "../assets/arrow_right.svg";
import { ReactComponent as ArrowLeftIcon } from "../assets/arrow_left.svg";
import { ReactComponent as SendIcon } from "../assets/send.svg";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Typography,
  Snackbar} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/system';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';


const DropzoneBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: "2px dashed #1976d2",
  borderRadius: "5px",
  backgroundColor: "#f3f8ff",
  cursor: "pointer",
  "&:hover": {
    borderColor: theme.palette.primary.dark,
  },
}));

const ResultTextField = styled(TextField)(({ theme }) => ({
  position: 'relative',
}));

const IconButton = styled(Button)({
  minWidth: 0,
  padding: 0,
  color: "gray",
});

function Content({changeMode, onDrop, pdfName, selectedType, handleTypeChange, selectedMode, user, handleSubmit, setModalIsOpen, isLoading, results, handlePrevResult, handleNextResult, handleCopy, currentIndex, chatMessage, setChatMessage, handleSendMessage, isSending, error, copySuccess}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {'application/pdf': ['.pdf']},
    maxSize: 10 * 1024 * 1024,
  });

  let modeText = "Select what type of section the AI should write"
  let chatText = "Explain how the text could be better"
  if (selectedMode === "critique"){
  modeText = "Select what type of feedback you want from the AI"
  chatText = "Suggest improvements to the feedback"
  }else if(selectedMode === "explain"){
  modeText = "Select how complicated the AI's explaination should be"
  chatText = "Ask follow up questions"
  }
  return (
<Container maxWidth="sm" sx={{ pb: 4 }}>
 
<Typography variant="h4" component="h1" align="center" gutterBottom style={{marginTop: "50px"}}>
AcademicGPT
</Typography>
<Grid container spacing={2} direction="column">
<Grid item>
<DropzoneBox {...getRootProps()} textAlign="center">
<input {...getInputProps()} />
{isDragActive ? (
  <p>Drop PDF here...</p>
) : (
  <p>Drop research paper here or click to select</p>
)}
</DropzoneBox>
</Grid>
<Grid item>
{pdfName && (
  <Typography variant="body1" align="center">
  Selected file: {pdfName}
  </Typography>
)}
</Grid>
<Grid item>
  <Grid item>
        <RadioGroup
          row
          value={selectedMode}
          onChange={changeMode}
        >
          <FormControlLabel value="write" control={<Radio />} label="Write" />
          <FormControlLabel value="critique" control={<Radio />} label="Feedback" />
          <FormControlLabel value="explain" control={<Radio />} label="Explain" />
        </RadioGroup>
      </Grid>
</Grid>
<Grid item>
 <Typography variant="body1" align="left">
  {modeText}
 </Typography>
</Grid>
<Grid item>
{selectedMode === 'write' && (
<Select fullWidth value={selectedType} onChange={handleTypeChange} displayEmpty>
  <MenuItem value="abstract">Abstract</MenuItem>
  <MenuItem value="conclusion">Conclusion</MenuItem>
  <MenuItem value="summary">Summary</MenuItem>
  <MenuItem value="future">Future work section</MenuItem>
  <MenuItem value="ethics">Ethical considerations section</MenuItem>
  <MenuItem value="presentation">Presentation outline</MenuItem>
  <MenuItem value="latex">LaTeX template</MenuItem>
</Select>
)}
{selectedMode === 'critique' && (
<Select fullWidth value={selectedType} onChange={handleTypeChange} displayEmpty>
  <MenuItem value="arguments">Argument strength</MenuItem>
  <MenuItem value="language">Language</MenuItem>
  <MenuItem value="coherency">Coherency</MenuItem>
  <MenuItem value="originality">Originality</MenuItem>
  <MenuItem value="citation">Citation</MenuItem>
</Select>
)}
{selectedMode === 'explain' && (
<Select fullWidth value={selectedType} onChange={handleTypeChange} displayEmpty>
  <MenuItem value="simpleshort">Simple and Short</MenuItem>
  <MenuItem value="simplelong">Simple and Long</MenuItem>
  <MenuItem value="complexshort">Complex and Short</MenuItem>
  <MenuItem value="complexlong">Complex and Long</MenuItem>
</Select>
)}
</Grid>
<Grid item>
<Button
variant="contained"
color="primary"
fullWidth
onClick={user ? handleSubmit : () => setModalIsOpen(true)}
disabled={isLoading}
>
{isLoading ? (
  <CircularProgress size={24} color="white" />
) : (
'Submit'
)}
</Button>
</Grid>

      {results.length > 0 && (
        <Grid item>
          <Grid container alignItems="center" justifyContent="space-between">
            <IconButton
              size="small"
              onClick={handleCopy}
              sx={{ marginRight: 1 }}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
            {results.length > 1 && (
              <div>
                <IconButton
                  size="small"
                  onClick={handlePrevResult}
                  sx={{ marginLeft: 1 }}
                >
                  <ArrowLeftIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={handleNextResult}
                  sx={{ marginLeft: 1 }}
                >
                  <ArrowRightIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>
      )}
      {results.length > 0 && (
        <Grid item>
          <ResultTextField
            fullWidth
            multiline
            label={`Result ${currentIndex + 1} of ${results.length}`}
            value={results[currentIndex]}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      )}
          {results.length > 0 && (
            <>
      <Grid item>
       <Typography variant="body1" align="left">
        {chatText}
       </Typography>
      </Grid>
        <Grid item>
                      <TextField
            fullWidth
             onKeyDown={(e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  }}
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            label="Chat"
            placeholder="Write to the AI..."
            inputProps={{
              maxLength: 300,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={handleSendMessage}
                    sx={{ marginRight: 1 }}
                    disabled={isSending}
                  >
                    {isSending ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <SendIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

            </>
      )}

    </Grid>
    <Snackbar
    open={!!error}
    message={error}
    autoHideDuration={3000}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
    <Snackbar
    open={copySuccess}
    message="Copied to clipboard"
    autoHideDuration={3000}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
    <Box align="center" style={{margin:"20px"}}>
    <Typography variant="body1" align="center">
      Feedback is appreciated! lukas.academicgpt@gmail.com
    </Typography>
  <a href="https://www.producthunt.com/posts/academicgpt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-academicgpt" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=395429&theme=light" alt="AcademicGPT - AI&#0045;driven&#0032;platform&#0032;for&#0032;accelerating&#0032;academic&#0032;writing | Product Hunt"  width="250" height="54" /></a>
  </Box>
    </Container>
  );


}
export default Content

