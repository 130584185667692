import { httpsCallable } from 'firebase/functions';
import { functions } from '../index.js';

export async function redirectToCustomerPortal(uid, setLoading) {
  try {
    const customerPortalLinkRef = httpsCallable(  
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await customerPortalLinkRef({
      returnUrl: window.location.origin
    });
    console.log(data);
    window.location.href = data.url
    setLoading(false)
  } catch (error) {
    console.log(error);
  }
}

