import { useState } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { db, analytics } from '../index.js';
import { logEvent } from "firebase/analytics";

function RoleDialog({ user, open, handleClose }) {
    const [selectedRole, setSelectedRole] = useState('');
    const [otherRole, setOtherRole] = useState('');

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleOtherRoleChange = (event) => {
        setOtherRole(event.target.value);
    };

  const saveUnspec = async () => {
        logEvent(analytics, "unspec role");
      await setDoc(doc(db, 'users', user.uid), { role: 'unspecified' }, { merge: true });
        handleClose();
  }

    const saveRole = async () => {
        const role = selectedRole === 'other' ? otherRole : selectedRole;
        logEvent(analytics, role);
        await setDoc(doc(db, 'users', user.uid), { role }, { merge: true });
        handleClose();
    };

    return (
        <Dialog open={open} onClose={saveUnspec}>
            <DialogTitle>May I ask what your role is?</DialogTitle>
            <DialogContent>
                <RadioGroup aria-label="role" name="role" value={selectedRole} onChange={handleRoleChange}>
                    <FormControlLabel value="student" control={<Radio />} label="Student" />
                    <FormControlLabel value="researcher" control={<Radio />} label="Researcher" />
                    <FormControlLabel value="industry" control={<Radio />} label="Industry" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
                {selectedRole === 'other' && <TextField placeholder="Please specify" onChange={handleOtherRoleChange} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={saveUnspec} color="gray">Rather not say</Button>
                <Button onClick={saveRole} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default RoleDialog
