import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton as IconButtonM,
  TextField
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Google as GoogleIcon } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

const AuthModal = ({
  modalIsOpen,
  handleCloseModal,
  handleSubmitAuth,
  signInState,
  forgotPasswordState,
  email,
  setEmail,
  password,
  setPassword,
  signInWithGoogle,
  handleToggleSignIn,
  handleForgotPasswordState,
  passwordError,
  emailError
}) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Dialog open={modalIsOpen} onClose={handleCloseModal}>
      <DialogTitle>
        {signInState
          ? 'Sign In'
          : forgotPasswordState
          ? 'Reset Password'
          : 'Sign Up'}
        <IconButtonM edge="end" color="inherit" onClick={handleCloseModal} aria-label="close">
          <CloseIcon />
        </IconButtonM>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmitAuth}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            helperText={emailError}
            error={emailError? true:false}
          />
          {!forgotPasswordState && (
            <TextField
  variant="outlined"
  margin="normal"
  required
  fullWidth
  name="password"
  label="Password"
  type={showPassword ? "text" : "password"}
  id="password"
  autoComplete="current-password"
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  helperText={passwordError}
  error={passwordError ? true : false}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  }}
/>

          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ textTransform: 'none', marginTop: '10px', marginBottom: '10px' }}
          >
            {signInState
              ? 'Sign In'
              : forgotPasswordState
              ? 'Send Email'
              : 'Sign Up'}
          </Button>
          {!signInState && !forgotPasswordState && (
            <Button
              variant="outlined"
              color="inherit"
              startIcon={<GoogleIcon />}
              onClick={signInWithGoogle}
              style={{ textTransform: 'none', marginTop: '10px', marginBottom: '10px' }}
            >
              Sign In with Google
            </Button>
          )}
          {!forgotPasswordState
            ? signInState
              ? [
                  <Button color="primary" onClick={handleForgotPasswordState} style={{ textTransform: 'none', marginTop: '10px', marginBottom: '10px' }}>
                    Forgot Password?
                  </Button>,
                  <Button color="primary" onClick={handleToggleSignIn} style={{ textTransform: 'none', marginTop: '10px', marginBottom: '10px' }}>
                    Sign Up Instead
                  </Button>
                ]
              : <Button color="primary" onClick={handleToggleSignIn} style={{ textTransform: 'none', marginTop: '10px', marginBottom: '10px' }}>
                  Sign In Instead
                </Button>
            : <Button color="primary" onClick={handleToggleSignIn} style={{ textTransform: 'none', marginTop: '10px', marginBottom: '10px' }}>
                Back to Sign In
              </Button>
          }
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AuthModal;

