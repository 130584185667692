import React, { useCallback, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { logEvent } from "firebase/analytics";
import { analytics, auth, db} from './index.js';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail, deleteUser } from "firebase/auth";
import Sidebar from "./utils/Sidebar.js"
import AuthModal from "./utils/AuthModal"
import Content from "./utils/Content.js"
import RoleDialog from "./utils/RoleDialog.js"
import { doc, collection, addDoc ,setDoc, getDoc ,getDocs, updateDoc, increment, onSnapshot, serverTimestamp} from "firebase/firestore";


function checkUserRole(user) {
    if (!user) return Promise.resolve(false);

    const userDoc = doc(db, 'users', user.uid);

    return getDoc(userDoc)
        .then(userSnapshot => {
            const userData = userSnapshot.data();
            return userData && userData.role !== undefined;
        });
}

function App() {
  const [pdfFile, setPdfFile] = useState(null);
  const [selectedType, setSelectedType] = useState('abstract');
  const [selectedMode, setSelectedMode] = useState('write');
  const [copySuccess, setCopySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [compressedText, setCompressedText] = useState("");
  const [results, setResults] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [chatMessage, setChatMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [user, setUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [signInState, setSignIn] = useState(false);
  const [forgotPasswordState, setForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [usage, setUsage] = useState(0);
  const [plan, setPlan] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openPriceDialog, setOpenPriceDialog] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [userHistory, setUserHistory] = useState([]);
  const [roleDialogOpen, setRoleDialog] = useState(false);


const loadHistory = async (session_id) => {
  setSessionId(session_id)
  // Get the document data from the firebase doc
  const docRef = doc(db, 'users', user.uid, 'history', session_id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    setCompressedText(data.compressedText);
    setResults(data.results);
    setCurrentIndex(data.results.length-1);
    setChatHistory(data.chatHistory);
  } else {
    console.log("No such document!");
  }
}


  const updateResults = (session_id, compressed, res, idx, chat_history) => {
    setCompressedText(compressed);
    setResults(res);
    setCurrentIndex(idx);
    setChatHistory(chat_history)
    if (user) {
        const historyRef = doc(db, 'users', user.uid, 'history', session_id);

        getDoc(historyRef)
          .then(docSnap => {
            if (!docSnap.exists()) {
              const historyDoc = {
                name: pdfFile.name,
                results: res,
                compressedText: compressed,
                chatHistory: chat_history,
                selectedType: selectedType,
                selectedMode: selectedMode,
                createdAt: serverTimestamp(),
                lastModified: serverTimestamp(),
              };

              return setDoc(historyRef, historyDoc);
            } else {
              const historyDoc = {
                results: res,
                chatHistory: chat_history,
                lastModified: serverTimestamp(),
              };

              return setDoc(historyRef, historyDoc, { merge: true });
            }
          })
          .then(() => {
            console.log("Session data updated on Firestore");
          })
          .catch(error => {
            console.error("Error adding/updating session data: ", error);
          });
      }
    };



  const addUser = (user) => {
    logEvent(analytics, "add user");
    const collectionRef = collection(db, "users");
    getDocs(collectionRef)
      .then((querySnapshot) => {
        let collectionExists = false;
        querySnapshot.forEach((doc) => {
          if (doc.id === user.uid) {
            // The user document already exists in the collection.
            collectionExists = true;
          }
        });

        if (!collectionExists) {
          // The user collection doesn't exist, so let's create it.
          const docRef = doc(db, "users", user.uid);
          setDoc(docRef, {
            uid: user.uid,
            email: user.email,
            usage: 0,
            createdAt: serverTimestamp(),
            lastLogin: serverTimestamp(),
          })
            .then(() => {
              console.log("User added to Firestore");
            })
            .catch((error) => {
              showMsg(error?.code, 4000)
              console.error("Error adding user: ", error);
            });
        } else {
          const userRef = doc(db, 'users', user.uid);
          try {
            updateDoc(userRef, {
              lastLogin: serverTimestamp()
            });
            console.log('User lastLogin updated successfully');
          } catch (error) {
            console.error('Error updating user: ', error);
          }
        }
      })
      .catch((error) => {
        showMsg(error?.code, 4000)
        console.error("Error checking collection existence: ", error);
      });
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      handleCloseModal()

      if (user) {
        setUser(user);
        addUser(user);
        checkUserRole(user).then(hasRole => {
            if (!hasRole) setRoleDialog(true);
        });

      } else {
        setUser(null);
        setCompressedText("");
        setResults([]);
        setSessionId("");
        setCurrentIndex(0);
        setChatHistory([])
        setIsLoading(false);
        setIsSending(false);
        setPdfFile(null)
        setSelectedType('abstract')
        setSelectedMode("write")
        setUserHistory([])
      }
    });
    
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if(user){
      const unsub = onSnapshot(collection(db, "users", user.uid, "subscriptions"), (querySnapshot) => {
        let unlimited = false;
        let premium = false
        querySnapshot.forEach((doc) => {
          if (doc.data().status === 'active') {
            if (doc.data().product.id === 'prod_NvQXJ0Bot7koiG'){
              unlimited = true
            }
            else if (doc.data().product.id === 'prod_NvQXYmQWPRtHR8'){
              premium = true
            }
          }
        });
        setPlan(unlimited ? 'Unlimited Plan' : ( premium ? "Premium Plan" : 'Free Plan'));
      });
      return () => unsub();
    }
  }, [user]);

  useEffect(() => {
  if(user){
    const unsub = onSnapshot(doc(db, "users", user.uid), (doc) => {
      if (doc.data()){
        setUsage(doc.data().usage);
      }
    });

    // Detach the listener when the component is unmounted or user changes
    return () => unsub();
  }
}, [user]);


  useEffect(() => {
    const getUserHistory = async () => {
      const userHistoryCollection = collection(db, 'users', user.uid, 'history');
      const userHistorySnapshot = await getDocs(userHistoryCollection);
      const historyData = userHistorySnapshot.docs.map(doc => [doc.data().name, doc.id]);
      setUserHistory(historyData.reverse());
      console.log("User history updated")
    };

    if (user && sidebarOpen){
    getUserHistory();
    }
  }, [sidebarOpen, results]);


  function handleAuthError(errorCode) {
    console.log(errorCode)
    switch (errorCode) {
        case 'auth/email-already-in-use':
            setEmailError('Email is already registered.');
            break;
        case 'auth/invalid-email':
            setEmailError('Invalid email format.');
            break;
        case 'auth/wrong-password':
            setPasswordError('Wrong password.');
            break;
        case 'auth/weak-password':
            setPasswordError('Password must be 6+ characters.');
            break;
        case 'auth/user-not-found':
            setEmailError('No user with this email.');
            break;
        default:
            showMsg(errorCode);
    }
}

  const signUp = useCallback(async (email, password) => {
    logEvent(analytics, "email signup");
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      handleAuthError(error?.code)
      console.error('Error signing up', error);
    }
  }, []);

  const signIn = useCallback(async (email, password) => {
    logEvent(analytics, "email signin");
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      handleAuthError(error?.code)
      console.error('Error signing in', error?.text);
    }
  }, []);

  const signInWithGoogle = useCallback(async () => {
    logEvent(analytics, "sign in google");
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error) {
      handleAuthError(error?.code)
      console.error('Error signing in with Google', error);
    }
  }, []);

  const sendResetEmail = useCallback(async (email) => {
    logEvent(analytics, "reset email");
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      handleAuthError(error?.code)
      console.error('Error sending password reset email', error);
    }
  }, []);

  const signOut = useCallback(async () => {
    logEvent(analytics, "sign out");
    try {
      await auth.signOut();
    } catch (error) {
      showMsg(error?.code, 4000)
      console.error('Error signing out', error);
    }
  }, []);

  const deleteAccount = useCallback(async () => {
    logEvent(analytics, "delete account");
    try {
      const user = auth.currentUser;
      if (user) {
        await deleteUser(user);
      }
    } catch (error) {
      showMsg(error?.code, 4000)
      console.error('Error deleting user', error);
    }
  }, []);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setEmail("");
    setPassword("");
    setSignIn(false);
    setForgotPassword(false);
  }

  const incrementUsage = (val) => {
    logEvent(analytics, "increment");
    const userDoc = doc(db, 'users', user.uid);
    updateDoc(userDoc, {
      usage: increment(val)
    }).then(() => {
      console.log("User usage incremented");
    }).catch((error) => {
      showMsg(error?.code, 4000)
      console.error("Error incrementing user usage: ", error);
    });
  }

  const handleToggleSignIn = () => {
      setEmailError("")
      setPasswordError("")
    setSignIn(!signInState);
    setForgotPassword(false);
  }

  const handleForgotPasswordState = () => {
      setEmailError("")
      setPasswordError("")
    setSignIn(false);
    setForgotPassword(true);

  }
    const handleSubmitAuth = useCallback(async (event) => {
    event.preventDefault();
      setEmailError("")
      setPasswordError("")
    if (signInState) {
      await signIn(email, password);
    } else if (forgotPasswordState) {
      await sendResetEmail(email);
    } else {
      await signUp(email, password);
    }
  }, [signIn, signInState, forgotPasswordState, email, password, signUp, sendResetEmail]);

    const handleSendMessage = async () => {
      if (!chatMessage) return;

      if ((plan === "Free Plan" && usage*800 > 100) || (plan == "Premium Plan" && usage*800 > 1000)){
        setSidebarOpen(true)
        setOpenPriceDialog(true);
        showMsg('No more credits - Upgrade plan', 6000);
        return;
      }

      setIsSending(true);

      const formData = new FormData();
      formData.append("compressed", compressedText);
      formData.append("result", results[currentIndex]);
      formData.append("history", JSON.stringify(chatHistory.slice(0, currentIndex+1)));
      formData.append("chat", chatMessage);
      formData.append("type", selectedType);
      formData.append("mode", selectedMode);

      try {
        const response = await fetch(
            "https://europe-west6-academicgpt-384705.cloudfunctions.net/academicgpt_update",

          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();

        if (response.status === 400) {
          showMsg(data.msg, 8000);
        }
        else{
          let res = [...results.slice(0, currentIndex+1), data.result];
          let chat_history = [...chatHistory.slice(0, currentIndex), chatMessage];
          updateResults(sessionId, compressedText, res, currentIndex+1, chat_history);
          incrementUsage(data.usage)
        }
        setIsSending(false);
      } catch (error) {
        console.error("Error:", error);
        setIsSending(false);
      }

      setChatMessage("");
    };


  const handlePrevResult = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextResult = () => {
    if (currentIndex < results.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const showMsg = (message, duration) => {
    logEvent(analytics, message);
    setError(message);
    setTimeout(() => {
      setError('');
    }, duration);
  };


const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
        const isSizeError = fileRejections.some(
            rejection => rejection.errors.some(
                error => error.code === 'file-too-large'
            )
        );

        if (isSizeError) {
            showMsg('File size exceeds 12MB', 4000);
        } else {
            showMsg('Only PDF files are accepted', 4000);
        }

        return;
    }

    logEvent(analytics, 'file dropped');
    setPdfFile(acceptedFiles[0]);
}, []);


  const handleTypeChange = (e) => {
    console.log(e.target.value)
    setSelectedType(e.target.value);
  };

  const changeMode = (e) => {
    let mode = e.target.value
    setSelectedMode(mode);
    if (mode === "write"){
      setSelectedType("abstract")
    }
    if (mode === "critique"){
      setSelectedType("arguments")
    }
    else if (mode === "explain"){
      setSelectedType("simpleshort")
    }
    else{
      setSelectedMode("write");
      setSelectedType("abstract")
    }
    
  };

  const handleSubmit = async () => {
    if (!pdfFile) {
      showMsg('Please select a PDF file', 4000);
      return;
    }

    if ((plan === "Free Plan" && usage*800 > 100) || (plan == "Premium Plan" && usage*800 > 1000)){
      setSidebarOpen(true)
      setOpenPriceDialog(true);
      showMsg('No more credits - Upgrade plan', 6000);
      return;
    }

    logEvent(analytics, selectedMode);
    logEvent(analytics, selectedType);

    setIsLoading(true);

    const formData = new FormData();
    formData.append('pdf', pdfFile);
    formData.append('mode', selectedMode);
    formData.append('type', selectedType);

    try {
        const response = await fetch('https://europe-west6-academicgpt-384705.cloudfunctions.net/academicgpt', {

        method: 'POST',
        body: formData,
      });

      if (response.status === 400) {
        const data = await response.json();
        showMsg(data.msg, 8000);
      }
      if (response.status === 200) {
        const data = await response.json();
        let session_id = new Date().getTime().toString()
        setSessionId(session_id);
        updateResults(session_id, data.compressed, [data.result], 0, []);
        incrementUsage(data.usage)
      }

      setIsLoading(false);
      setIsSending(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(results[currentIndex]).then(() => {
      setCopySuccess(true);
      logEvent(analytics, "copy");
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    });
  };

  return (
    <div>
    <RoleDialog user={user} open={roleDialogOpen} handleClose={() => setRoleDialog(false)} />
    <Button
  variant="outlined"
  color="primary"
  style={{ position: 'fixed', margin: '10px', marginTop:"-40px",background:"white"}}
  onClick={user ? () => setSidebarOpen(true) : () => setModalIsOpen(true)}
>
  {user ? "Account" : "Sign Up"}
    </Button>
    {sidebarOpen && user && (
    <Sidebar
      isOpen={sidebarOpen}
      user={user}
      logout={signOut}
      deleteAccount={deleteAccount}
      toggle={() => setSidebarOpen(false)}
      usage={usage}
      plan={plan}
      openPriceDialog={openPriceDialog}
      setOpenPriceDialog={setOpenPriceDialog}
      userHistory={userHistory}
      loadHistory={loadHistory}
    />
  )}
    <AuthModal
      modalIsOpen={modalIsOpen}
      handleCloseModal={handleCloseModal}
      handleSubmitAuth={handleSubmitAuth}
      signInState={signInState}
      forgotPasswordState={forgotPasswordState}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      signInWithGoogle={signInWithGoogle}
      handleToggleSignIn={handleToggleSignIn}
      handleForgotPasswordState={handleForgotPasswordState}
      passwordError={passwordError}
      emailError={emailError}
    />
    <Content
  onDrop={onDrop}
  pdfName={pdfFile? pdfFile.name:""}
  selectedType={selectedType}
  handleTypeChange={handleTypeChange}
  selectedMode={selectedMode}
  changeMode={changeMode}
  user={user}
  handleSubmit={handleSubmit}
  setModalIsOpen={setModalIsOpen}
  isLoading={isLoading}
  results={results}
  handlePrevResult={handlePrevResult}
  handleNextResult={handleNextResult}
  handleCopy={handleCopy}
  currentIndex={currentIndex}
  chatMessage={chatMessage}
  setChatMessage={setChatMessage}
  handleSendMessage={handleSendMessage}
  isSending={isSending}
  error={error}
  copySuccess={copySuccess}
/>
  </div>
  );
}

export default App;


