import { doc, collection, addDoc, onSnapshot } from "firebase/firestore";
import getStripe from "./initializeStripe";
import { db} from '../index.js';

export async function createCheckoutSession(uid, price) {
  // Create a new checkout session in the subollection inside this users document
  const checkoutSessionRef = await addDoc(collection(db, "users", uid, "checkout_sessions"), {
    price: price,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  });

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(doc(db, "users", uid, "checkout_sessions", checkoutSessionRef.id), async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    }
  });
}

