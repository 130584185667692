import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import './index.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';



const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // You can customize the color here
    },
    secondary: {
      main: '#dc004e', // You can customize the color here
    },
    white: {
      main: '#ffffff', // You can customize the color here
    },
    gray: {
      main: "#7d7d7d", // You can customize the color here
    },
  },
});

const firebaseConfig = {
  apiKey: "AIzaSyA92yAn6D8eciRM4b61S0IWez5ihpI92p0",
  authDomain: "academicgpt-384705.firebaseapp.com",
  projectId: "academicgpt-384705",
  storageBucket: "academicgpt-384705.appspot.com",
  messagingSenderId: "300130531038",
  appId: "1:300130531038:web:c648fc0f34e1e106a6b44f",
  measurementId: "G-YSYZ63BK0H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, "europe-west1");

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
      <App />
  </ThemeProvider>,
  document.getElementById('root')
);

