import {Box, Typography, Button, IconButton, Drawer,  List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Divider} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material'; 
import { useState} from 'react';                    
import { redirectToCustomerPortal } from '../stripe/redirectToCustomerPortal.js';
import { createCheckoutSession } from '../stripe/createCheckoutSession.js';

function Sidebar({ isOpen, user, logout, deleteAccount, toggle , usage, plan, openPriceDialog, setOpenPriceDialog, userHistory, loadHistory}) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeleteAccount = () => {
    setOpenDeleteDialog(true);
  }

  const handleOpenPrice = () => {
    setOpenPriceDialog(true);
  }

  const handleSubscribe = (price) => {
    setLoading(true)
    createCheckoutSession(user.uid, price)
  }

  const handleCloseDialog = () => {
    setOpenDeleteDialog(false);
    setOpenPriceDialog(false);
  }

  const handleConfirmDelete = () => {
    deleteAccount();
    handleCloseDialog();
  }

  const handleManageSubscription = () => {
    if(user){
      setLoading(true)
      redirectToCustomerPortal(user.uid, setLoading);
    }
  }

  const clickHistory = (id) => {
    loadHistory(id)
    toggle()
  }

  return (
    <>
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={toggle}
    >
    <Box>
      <IconButton display="inline-block" onClick={toggle} style={{alignSelf: "flex-start"}}>
        <CloseIcon />
      </IconButton>
    {userHistory.length > 0 && (<Typography display="inline-block" variant="h7" marginLeft="25%" align="center">
                History
            </Typography>)}
    </Box>
     <Box style={{overflow: 'auto', height:"100%", minHeight:"80px"}}>
            <List component="nav" aria-label="user history">
                {userHistory.map((entry, index) => (
                  <ListItem button key={index} onClick={() => clickHistory(entry[1])}>
                      <ListItemText primary={entry[0]} />
                  </ListItem>
                ))}
            </List>
        </Box>
      <List style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'column', height: '100%', padding:"0px"}}>
        <div>
          <Divider />
    <Typography marginLeft="38%" variant="h7" align="center">
                Account
            </Typography>
          <ListItem>
            <ListItemText primaryTypographyProps={{style:{fontStyle: 'italic'}}} primary={user ? user.email : ''} />
          </ListItem>
          <ListItem>
            <ListItemText primaryTypographyProps={{style:{fontStyle: 'italic'}}} primary={plan} />
          </ListItem>
          <ListItem>
            <ListItemText primaryTypographyProps={{style:{fontStyle: 'italic'}}} primary={(plan === 'Unlimited Plan' ? "" : ('Credits used: '+Math.round(usage*800)+'/'+(plan==="Free Plan" ? "100":"1000")))} />
          </ListItem>
          <ListItem>
            <Button variant="contained" color="primary" onClick={logout} fullWidth>
              Logout
            </Button>
          </ListItem>
    {/*          <ListItem>
            <Button variant="contained" color="primary" onClick={handleDeleteAccount} fullWidth>
              Delete account
            </Button>
          </ListItem>*/}
          <ListItem>
            {plan === 'Free Plan' ?
              <Button variant="contained" color="primary" onClick={handleOpenPrice} disabled={loading} fullWidth>
    {loading ? (
      <CircularProgress size={24} color="white" />
    ) : (
'Upgrade Plan'
    )}
              </Button>
              :
              <Button variant="contained" color="primary" onClick={handleManageSubscription} disabled={loading} fullWidth>
    {loading ? (
      <CircularProgress size={24} color="white" />
    ) : (
'Manage Subscription'
    )}
              </Button>
            }
          </ListItem>
        </div>
      </List>
    </Drawer>
    <Dialog
      open={openDeleteDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you really want to delete your account? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          No
        </Button>
        <Button onClick={handleConfirmDelete} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
  open={openPriceDialog}
  onClose={handleCloseDialog}
>
  <DialogContent>
    {loading ? (
      <CircularProgress size={24} />
    ) : (
      <>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" align="center" color="primary">
            Upgrade Plan
          </Typography>
        </DialogTitle>
        <Box sx={{ mb: 2, textAlign: 'center' }} paddingRight="40px" display="inline-block">
          <Button variant="contained" color="primary" onClick={() => handleSubscribe( "price_1NOd5HJIGhEasScuBdzj9qB1")} disabled={loading} size="large">
            Go Premium
          </Button>
          <Typography variant="h6" color="text.primary" marginTop="10px">
            10$/month
          </Typography>
          <Typography variant="body1" color="text.secondary">
            1000 credits / month
          </Typography>
        </Box>
        <Box sx={{textAlign: 'center'}} display="inline-block">
          <Button variant="contained" color="primary" onClick={() => handleSubscribe("price_1N9ZgUJIGhEasScuwIJV5v5s")} disabled={loading} size="large">
            Go Unlimited
          </Button>
          <Typography variant="h6" color="text.primary" marginTop="10px">
            20$/month
          </Typography>
          <Typography variant="body1" color="text.secondary">
            No usage limit
          </Typography>
        </Box>
      </>
    )}
  </DialogContent>
</Dialog>


    </>
  );
}
export default Sidebar

